import React from 'react';
import Box from 'components/box';

const NotFound = () => (
  <div>
    <Box>Not found.</Box>
  </div>
);

export default NotFound;
